<template>
  <component
    :is="$attrs.to ? 'nuxt-link' : 'button'"
    class="d-btn"
    v-bind="$attrs"
    :disabled="disabled || loading"
    :class="computedClass()"
  >
    <d-spinner v-if="loading" class="d-btn--loader"></d-spinner>
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { ColorEnum, SizeEnum, sizeValidator } from "~/components/_base-ts/DBtn";

export default defineComponent({
  inheritAttrs: false,
  props: {
    size: {
      type: String as PropType<SizeEnum>,
      default: SizeEnum.MEDIUM,
      validator: sizeValidator,
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.DEFAULT,
      validator: (v: string) => v.toUpperCase() in ColorEnum,
    },
    loading: Boolean,
    disabled: Boolean,
    icon: Boolean,
  },
  setup(props: any) {
    const computedClass = () => {
      return [
        `d-btn--${props.size}`,
        `d-btn--${props.color}`,
        {
          "d-btn--icon": props.icon,
        },
      ];
    };

    return {
      computedClass,
    };
  },
});
</script>

<style lang="scss">
.d-btn {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  border-radius: 28px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;

  &--loader {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    &:after {
      width: 20px !important;
      height: 20px !important;
      border-color: color(primary) color(primary) color(primary) transparent !important;
    }
  }

  &:disabled {
    color: color(white);
    background: color(gray);
    border-color: color(gray);
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px rgba(238, 60, 107, 0.2);
  }

  &--large {
    font-size: fontSize(title);
    padding: 16px 40px;
  }

  &--medium {
    font-size: fontSize(title);
    padding: 18px 24px;

    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(paragraph);
      line-height: 22px;
      padding: 9px 24px;
    }

    &.d-btn--prepend {
      padding-left: 12px;
    }
  }

  &--small {
    font-size: fontSize(label);
    height: 24px;

    &.d-btn--prepend {
      padding-left: 6px;

      .d-icon:first-child {
        margin-right: 6px;
      }
    }

    &.d-btn--append {
      padding-right: 6px;

      .d-icon:last-child {
        margin-left: 6px;
      }
    }
  }

  &--icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    box-shadow: none !important;
    padding: 6px;

    &.d-btn--medium {
      width: 40px;
      height: 40px;
    }

    &.d-btn--large {
      width: 44px;
      height: 44px;
    }

    &.d-btn--x-large {
      width: 48px;
      height: 48px;
    }

    &.d-btn--small {
      width: 24px;
      height: 24px;
      margin: -4px;
    }
  }

  &--custom {
  }

  &--default,
  &--error {
    color: color(primary);
    background: color(white);
    border: 1px solid color(primary);

    &:hover:not(:disabled) {
      color: color(primary-dark);
      border-color: color(primary-dark);
    }
  }

  &--primary {
    background: color(primary);
    color: color(white);

    &:hover:not(:disabled) {
      background: color(primary-dark);
    }

    &:active {
      background: color(primary-dark);
    }
  }
  &--primary-light {
    background: color(primary-light);
    color: color(primary);

    &:hover:not(:disabled) {
      background: #ffe9ef;
    }

    &:active {
      background: #ffe9ef;
    }
  }

  &--gradient {
    background: color(gradient);
    color: color(white);

    &:hover:not(:disabled) {
      background: color(gradient);
    }

    &:active {
      background: color(gradient);
    }
  }

  &--white-primary {
    background: color(white);
    color: color(primary);
    border: 1px solid color(primary);

    &:hover:not(:disabled) {
      color: color(primary-dark);
      border: 1px solid color(primary-dark);
    }

    &:active {
      color: color(primary-dark);
      border: 1px solid color(primary-dark);
    }
  }

  &--semi-primary {
    background: color(primary-light);
    color: color(primary);
    border: 1px solid transparent;

    &:hover:not(:disabled) {
      color: color(primary);
      border: 1px solid transparent;
    }

    &:active {
      color: color(primary);
      border: 1px solid transparent;
    }
  }

  &--white-additional {
    background: color(white);
    color: color(additional-light);
    border: 1px solid color(additional-light);

    &:hover:not(:disabled) {
      color: color(additional);
      border: 1px solid color(additional);
    }

    &:active {
      color: color(additional);
      border: 1px solid color(additional);
    }
  }

  &--white-secondary {
    background: color(white);
    color: color(secondary);
    border: 1px solid color(secondary);

    &:hover:not(:disabled) {
      color: color(secondary-dark);
      border: 1px solid color(secondary-dark);
    }

    &:active {
      color: color(secondary-dark);
      border: 1px solid color(secondary-dark);
    }
  }

  &--white-gray {
    background: color(white);
    color: color(gray-dark);
    border: 1px solid color(gray-dark);

    &:hover:not(:disabled) {
      color: color(black);
      border: 1px solid color(black);
    }

    &:active {
      color: color(black);
      border: 1px solid color(black);
    }
  }

  &--additional {
    background: color(additional);
    color: color(white);

    &:hover:not(:disabled) {
      background: color(additional-light);
    }

    &:active {
      background: color(additional-light);
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(color(additional-light), 0.2);
    }
  }

  &--additional-light {
    background: color(additional-light);
    color: color(white);

    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(color(additional-light), 0.2);
    }
  }

  &--white {
    background: transparent;
    color: color(white);
    border: 1px solid color(white);

    &:hover:not(:disabled) {
      background: transparent;
    }

    &:active {
      background: color(white-additional);
    }
  }

  &--default.d-btn--icon {
    .d-icon {
      color: color(primary);
    }

    &:hover:not(:disabled),
    &:active {
      .d-icon {
        color: color(primary-dark);
      }
    }
  }

  &--error.d-btn--icon {
    .d-icon {
      color: color(red);
    }

    &:hover:not(:disabled),
    &:active {
      .d-icon {
        color: color(red);
      }
    }
  }
}
</style>
